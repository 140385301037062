<div
    class="sticky top-0 z-40 flex max-h-screen items-center justify-between overflow-auto border-b border-grey-200 bg-white px-24 lg:justify-start"
>
    <a class="flex items-center lg:order-2" [hyundaiRouterLink]="['/', dealer().slug]">
        <i class="icon-logo text-24 text-accent" title="Hyundai Logo"></i>

        <div class="mx-[11px] h-[32px] w-1 bg-grey-200"></div>
        <div class="flex flex-col font-medium">
            <span class="text-14 leading-[14px]">Ihr Partner</span>

            <span class="font-headline text-16 leading-[16px]">{{ dealer().name }}</span>
        </div>
    </a>

    <button
        class="flex h-[50px] min-w-[56px] shrink-0 items-center justify-end lg:order-1 lg:-ml-24 lg:min-w-[80px] lg:justify-start lg:pl-24"
        (click)="toggleMenu()"
        title="Menü"
    >
        <i class="text-24 text-accent" [ngClass]="showMenu() ? 'icon-nav-close' : 'icon-burger'"></i>
    </button>

    <div
        class="leading-21 absolute right-0 top-0 ml-auto hidden h-full items-center gap-24 text-14 text-accent lg:flex"
    >
        @defer (when login()) {
            @if (login()) {
                <div class="hidden h-full justify-around gap-8 bg-white xl:flex">
                    <a
                        class="mx-auto flex h-auto items-center bg-accent p-8 px-11 text-white"
                        [hyundaiRouterLink]="editCampaignUrl()"
                    >
                        AKTIONEN ERSTELLEN
                    </a>
                    <a
                        class="mx-auto flex h-full items-center border-2 border-accent px-8 font-headline"
                        [hyundaiRouterLink]="feedbackUrl"
                    >
                        FEEDBACK-FORMULAR
                    </a>
                    <button class="mx-auto h-full bg-accent p-8 px-11 text-white" (click)="logout()">ABMELDEN</button>
                </div>
            }
        }
        @if (dealer().hasSales && dealer().pages.testDriveForm) {
            <a
                class="group flex items-center gap-8"
                [trackingElTarget]="dealer().pages.testDriveForm"
                [hyundaiRouterLink]="dealer().pages.testDriveForm"
                trackingElLabel="Test Drive"
                trackingElType="navigation bar"
                tracking
                trackingEvent="engagement_click"
                target="_blank"
            >
                <i class="icon-wheel text-24"></i>
                <span class="group-hover:underline">Probefahrt</span>
            </a>
        }

        @if (dealer().hasService && dealer().email) {
            <a
                class="group flex items-center gap-8"
                [trackingElTarget]="dealerServiceMailLink()"
                [hyundaiRouterLink]="dealerServiceMailLink()"
                trackingElType="navigation bar"
                tracking
                trackingEvent="engagement_click"
                trackingElLabel="Service Request"
            >
                <i class="icon-service text-24"></i>
                <span class="group-hover:underline">Servicetermin</span>
            </a>
        }

        <a class="group flex h-full items-center gap-8 bg-grey-200 px-24" [hyundaiRouterLink]="'tel:' + dealer().phone">
            <i class="icon-phone text-24"></i>
            <span class="group-hover:underline">{{ dealer().phone }}</span>
        </a>
    </div>
</div>

@defer (when login()) {
    @if (login()) {
        <div class="flex justify-around gap-8 bg-white px-4 py-4 text-14 xl:hidden">
            <a
                class="h-auto flex-1 content-center bg-accent p-8 px-11 text-center text-white"
                [hyundaiRouterLink]="editCampaignUrl()"
            >
                AKTIONEN ERSTELLEN
            </a>
            <a
                class="h-auto flex-1 border-2 border-accent p-8 px-11 text-center font-headline"
                [hyundaiRouterLink]="feedbackUrl"
            >
                FEEDBACK-FORMULAR
            </a>
            <button class="h-auto flex-1 bg-accent p-8 px-11 text-white" (click)="logout()">ABMELDEN</button>
        </div>
    }
}
