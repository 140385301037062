import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, computed, inject } from '@angular/core';
import { DealerFeature, LoginFeature, RouterLinkDirective, TrackingDirective, UiFeature } from '@hyundai/ng-common-lib';
import { getEditDealerCampaignsUrl } from '@hyundai/shared-lib';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-nav-bar',
    standalone: true,
    imports: [RouterLinkDirective, NgClass, TrackingDirective],
    templateUrl: './nav-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class NavBarComponent {
    store = inject(Store);

    dealer = this.store.selectSignal(DealerFeature.state.selectLoadedDealer);
    dealerServiceMailLink = this.store.selectSignal(DealerFeature.state.selectDealerServiceFormUrl);
    showMenu = this.store.selectSignal(UiFeature.state.selectShowMenu);
    login = this.store.selectSignal(LoginFeature.state.selectIsLoggedIn);

    editCampaignUrl = computed(() => {
        const dealer = this.dealer();
        if (!dealer) return '';
        return getEditDealerCampaignsUrl(dealer.slug);
    });

    toggleMenu() {
        this.store.dispatch(this.showMenu() ? UiFeature.actions.hideMenu() : UiFeature.actions.showMenu());
    }

    logout() {
        this.store.dispatch(LoginFeature.actions.logout());
    }

    feedbackUrl =
        'https://support.hyundai-partners.de/support?utm_medium=email&_hsenc=p2ANqtz-95Dg34m7cp7HTCC6S7Jp1Bt3C3Assl79i-C0EgDylcQtUX4x3NR5czbnOOgvV7-3X357Px9C845tiPgOjMqD8z2_Uboy6asNRH7qT_-eZfYLKE_T0&_hsmi=2&utm_content=2&utm_source=hs_email';
}
